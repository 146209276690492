////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(203, 197, 180, 1);
}
.bg-secondaryColor {
  background-color: rgba(0, 70, 73, 0.83);
}
.bg-thirdColor {
  background-color: rgba(0, 70, 73, 1);
}
.bg-fourthColor {
  background-color: rgba(122, 168, 156, 1);
}
.bg-fifthColor {
  background-color: rgba(122, 168, 156, 0.78);
}
.bg-sixthColor {
  background-color: rgba(203, 197, 180, 1);
}
.bg-seventhColor {
  background-color: rgba(203, 197, 180, 1);
}
